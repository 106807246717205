//.side-nav .side-nav-inner .side-nav-logo > a .logo {
//  padding-bottom: 13px !important;
//  padding-top: 14px !important;
//}

.preparing {
  border-color: #1abc9c !important;
}

.completed {
  border-color: black !important;
}

.dropdown-menu {
  color: #888da8;
}

.project {
  width: 90%;
  height: 60px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #eeeeee;
  background-color: transparent;
  margin-top: 20px;
  margin-bottom: 10px;
}

.project-bottom {
  margin-top: 20px;
  width: 100%;
  height: 70px;
  background-color: #7774e7;
  border-radius: 0px 0px 6px 6px;
}

.order-pills {
  >li {
    +li {
      margin-left: 5px;
    }

    >a {
      border-radius: 50px;
      opacity: 0.75;
      font-size: 14px;
      font-weight: 500;
      font-family: $font-eddress !important;
      background-color: transparent;
      color: $default-text-color;
      border: 1px solid transparent;
      padding: 5px 15px;

      &:hover,
      &:focus {
        background-color: transparent;
        opacity: 1;
      }
    }

    >a {
      &.active {
        color: $default-white;
        border-color: $default-primary;
        background-color: $default-primary;
        border: 1px solid $default-primary;
        opacity: 1;

        &:hover,
        &:focus {
          color: $default-white;
          border-color: $default-primary;
          background-color: $default-primary;
          border: 1px solid $default-primary;
          opacity: 1;
        }

        &.nav-link {
          color: $default-primary;
          background-color: $default-primary-tran;
          border-color: transparent;
        }
      }
    }
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #e7edf0;
  border-radius: 50%;
  display: inline-block;
}

.dot-delivered {
  height: 10px;
  width: 10px;
  background-color: rgba(161, 218, 86, 1);
  border-radius: 50%;
  display: inline-block;
}

.dot-transit {
  height: 10px;
  width: 10px;
  background-color: rgba(255, 187, 68, 1);
  border-radius: 50%;
  display: inline-block;
}

.dot-warning {
  height: 10px;
  width: 10px;
  background-color: rgba(255, 102, 134, 1);
  border-radius: 50%;
  display: inline-block;
}

.dataTables_filter,
.dataTables_info {
  display: none;
}

//.dataTables_length, .dataTables_info { display: none; }

.form-control.validation-field.ng-touched {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.625rem;
  background-size: 1.25rem 1.25rem;

  &.ng-invalid {
    border-color: #d22e2e;
  }

  &.ng-valid {
    border-color: #4bae4f;
  }
}

.login-logo {
  max-width: 300px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
}

.login-swiffi-logo {
  max-width: 300px;
  margin-bottom: 90px;
  margin-top: 20px;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.ng-select.custom {
  border: 0px;
  min-height: 0px;
  border-radius: 0;
  width: 100%;
}

.ng-select.custom .ng-select-container {
  min-height: 0px;
  border-radius: 0;
}

.ng-select.ng-select-single .ng-select-container {
  border: 1px solid #e6ecf5;
  border-radius: 2px;
  box-shadow: none;
  height: 42px;
  //min-width: 171px;
  font-size: 14px;
  font-family: $font-eddress !important;
  color: #888da8;
}

.ng-select.ng-select-multiple .ng-select-container {
  border: 1px solid #e6ecf5;
  border-radius: 2px;
  box-shadow: none;
  height: 42px;
  font-size: 14px;
  font-family: $font-eddress !important;
  color: #888da8;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 9px;
  padding-bottom: 5px;
  padding-left: 3px;
  color: #ced4da;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #ced4da;
}

.input-group .custom {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.ellipsis-v:after {
  content: "\2807";
  font-size: 24px;
  font-family: $font-eddress !important;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: #3893cc !important;
}

.md-drppicker .btn {
  background-color: #3893cc !important;
}

.md-drppicker .clear svg {
  color: #3893cc !important;
  fill: currentColor !important;
}

.pastTime {
  color: #e74c3c;
}

.futureTime {
  color: #3498db;
}

.btn-selected {
  background-color: #edf2f8 !important;
}

.center-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-container-modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-title {
  font-style: initial;
  font-weight: 200;
  font-family: $font-eddress !important;
  font-size: 22px !important;
  color: #515365;
}

.no-background {
  border: none;
  padding: 1px;
  margin-bottom: 0%;
}

.no-background-custom {
  border: none;
  margin-bottom: 0%;
}

//PrimNG Table CSS
body {
  .modal {
    z-index: 3000 !important;
  }

  .ui-tooltip .ui-tooltip-text {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    width: 138px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    font-family: $font-eddress !important;
    letter-spacing: normal;
    color: #515365;
  }

  .ui-tooltip.ui-tooltip-right,
  .ui-tooltip.ui-tooltip-left {
    padding: 0 0.25em;
  }

  .ui-tooltip.ui-tooltip-top,
  .ui-tooltip.ui-tooltip-bottom {
    padding: 0.25em 0;
  }

  .ui-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .ui-tooltip-right .ui-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -0.25em;
    border-width: 0.25em 0.25em 0.25em 0;
    border-right-color: #ffffff;
  }

  .ui-tooltip-left .ui-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -0.25em;
    border-width: 0.25em 0 0.25em 0.25em;
    border-left-color: #ffffff;
  }

  .ui-tooltip.ui-tooltip-top {
    padding: 0.25em 0;
  }

  .ui-tooltip-top .ui-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -0.25em;
    border-width: 0.25em 0.25em 0;
    border-top-color: #ffffff !important;
  }

  .ui-tooltip-bottom .ui-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -0.25em;
    border-width: 0 0.25em 0.25em;
    border-bottom-color: #ffffff;
  }

  .tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }

  .tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #000;
  }

  .tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
  }

  .ui-table table {
    table-layout: auto;
    //width: 100%;
    //white-space: nowrap;
  }

  .ui-table {
    .ui-table-tbody {
      border-radius: 2px;
      //border: solid 1px #f6f6f6;
      background-color: #ffffff;

      >tr:nth-child(even) {
        background-color: #ffffff;
      }

      .primeng-tb-tr {
        height: 52px;

        td {
          height: 1px;
          border: none;
          border-bottom: solid 1px #f6f6f6;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          font-family: $font-eddress !important;
          line-height: normal;
          letter-spacing: normal;
          //color: #888da8;
          color: #515365;
        }
      }

      .primeng-tb-tr:hover {
        background-color: rgba(56, 147, 204, 0.05);
        //cursor: pointer;
      }

      .ui-widget-header {
        height: 45px;
        border-bottom: 1px solid #f6f6f6;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;

        .fa {
          color: #515365;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          font-style: normal;
          font-family: $font-eddress !important;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #515365;
          margin-left: 10px;
        }

        // please fix
        .primeng-colspan-border {
          border: none;
        }
      }
    }

    .ui-table-thead {
      tr>th {
        height: 50px;
        border-radius: 2px;
        //border: solid 1px #f6f6f6;
        border: 4px solid #f6f7fb;
        border-left: none;
        border-right: none;
        background-color: #ffffff;
        font-size: 12px !important;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        font-family: $font-eddress !important;
        line-height: normal;
        letter-spacing: normal;
        color: #888da8;
        //color: #515365;
      }
    }
  }
}

body {
  .ui-table table {
    table-layout: auto;
    //width: 100%;
    //white-space: nowrap;
  }

  .ui-table table {
    table-layout: auto;

    //width: 100%;
    //white-space: nowrap;
  }

  .ui-table {
    .ui-table-wrapper {
      //overflow-x: auto;
    }

    .ui-table-tbody {
      border-radius: 2px;
      //border: solid 1px #f6f6f6;
      background-color: #ffffff;

      >tr:nth-child(even) {
        background-color: #ffffff;
      }

      .primeng-tb-tr {
        height: 52px;

        td {
          height: 1px;
          border: none;
          border-bottom: solid 1px #f6f6f6;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-family: $font-eddress !important;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #515365;
          //color: #9cb1c3;
        }

        // add padding to each first column

        .padding-left-35 {
          padding-left: 35px;
        }
      }

      .primeng-tb-tr:hover {
        background-color: rgba(56, 147, 204, 0.05);
        //cursor: pointer;
      }

      .ui-widget-header {
        height: 45px;
        border-bottom: 1px solid #f6f6f6;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;

        .fa {
          color: #515365;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          font-family: $font-eddress !important;
          letter-spacing: normal;
          color: #515365;
          margin-left: 10px;
        }

        // please fix
        .primeng-colspan-border {
          border: none;
        }
      }
    }

    .ui-table-thead {
      text-transform: uppercase;

      tr>th {
        height: 50px;
        white-space: nowrap;
        border-radius: 2px;
        border: 4px solid #f6f7fb;
        border-left: none;
        border-right: none;
        background-color: #ffff;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        font-family: $font-eddress !important;
        line-height: normal;
        letter-spacing: normal;
        color: #888da8;
      }

      // add padding to each first column

      .padding-left-35 {
        padding-left: 35px;
      }
    }
  }
}

.ui-table .ui-sortable-column-icon {
  color: #888da8;
}

.ui-table .ui-paginator {
  height: 43px;
  padding-top: 5px;
  background-color: #ffffff;
  border: 0px;
  text-align: right;
  width: 100%;
  min-width: 486px;
}

// @media only screen and (max-width: 870px)  {
//   .ui-table .ui-paginator {
//     width: 103%;
//   }
// }

.ui-table .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #3893cc;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
}

.ui-paginator .ui-paginator-pages .ui-paginator-page:hover {
  color: #848484;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
  margin-top: -5px;
}

.ui-paginator .ui-dropdown {
  margin-top: -4px;
}

.ui-paginator-first {
  widows: 25px;
}

body .pi {
  font-size: 14px;
}

body .ui-table .ui-sortable-column a,
body .ui-table .ui-sortable-column a:hover {
  color: #515365;
}

body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: white;
  color: #515365;
}

body .ui-table .ui-sortable-column.ui-state-highlight a,
body .ui-table .ui-sortable-column.ui-state-highlight {
  color: #515365;
}

body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: white;
  color: #515365;
}

body .ui-table .ui-sortable-column.ui-state-highlight a,
body .ui-table .ui-sortable-column.ui-state-highlight a:hover {
  color: #515365;
}

// End PrimNG

.color-black {
  color: #9cb1c3 !important;
}

.color-dark-black {
  color: #515365 !important;
}

.color-green {
  color: #6bcc38 !important;
}

.mrg-right-0 {
  margin-right: 0px;
}

.min-width-0 {
  min-width: 0px !important;
}

.min-width-5 {
  min-width: 5em;
}

.min-width-6 {
  min-width: 6em;
}

.min-width-8 {
  min-width: 8em;
}

.min-width-9 {
  min-width: 9em;
}

.min-width-10 {
  min-width: 10em;
}

.min-width-11 {
  min-width: 11em;
}

.max-width-5 {
  max-width: 5em;
}

p-paginator {
  p-dropdown {
    float: left;
    margin-top: 5px;
  }

  p-dropdown::before {
    padding-left: 35px;
    content: " Show";
  }

  p-dropdown::after {
    content: "  entries";
    margin-top: 1px;
    position: absolute;
  }
}

.max-width-10 {
  max-width: 10em;
}

.max-width-13 {
  max-width: 13em;
}

.change-position {
  margin-left: 10px;
  margin-top: 9px;
  border: none;
  padding: 1px;
}

.gray-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: lightgrey;
}

.table-responsive {
  overflow: visible;
}

.custom-top {
  top: 6px !important;
}

//ngb-datepicker {
//  border: 1px solid #e6ecf5!important;
//  padding: 25px 20px!important;
//}

//To align the button with the card or datatable
.mrg-top-0 {
  margin-top: 0px !important;
}

.mrg-top-2 {
  margin-top: 2px !important;
}

.mrg-top-3 {
  margin-top: 3px !important;
}

.mrg-top-4 {
  margin-top: 4px !important;
}

.mrg-top-6 {
  margin-top: 6px !important;
}

.mrg-top-7 {
  margin-top: 7px !important;
}

.mrg-top-8 {
  margin-top: 8px !important;
}

.mrg-top-10 {
  margin-top: 10px !important;
}

.mrg-top-12 {
  margin-top: 12px !important;
}

.mrg-top-13 {
  margin-top: 13px;
}

.mrg-top-14 {
  margin-top: 14px;
}

.mrg-right-0 {
  margin-right: 0px !important;
}

.mrg-right-3 {
  margin-right: 3px !important;
}

.mrg-right-4 {
  margin-right: 4px !important;
}

.mrg-left-0 {
  margin-left: 0 !important;
}

.mrg-left-1 {
  margin-left: 1px !important;
}

.mrg-left-2 {
  margin-left: 2px !important;
}

.mrg-left-3 {
  margin-left: 3px !important;
}

.mrg-left-7 {
  margin-left: 7px !important;
}

.mrg-left-13 {
  margin-left: 13px !important;
}

.mrg-left-14 {
  margin-left: 14px !important;
}

.mrg-left-16 {
  margin-left: 16px !important;
}

.mrg-left-23 {
  margin-left: 23px !important;
}

.pdd-right-0 {
  padding-right: 0px !important;
}

.pdd-top-2 {
  padding-top: 2px !important;
}

.pdd-bottom-22 {
  padding-bottom: 22px !important;
}

.pdd-left-26 {
  padding-left: 26px !important;
}

.pdd-left-27 {
  padding-left: 27px !important;
}

.pdd-left-28 {
  padding-left: 28px !important;
}

.heading {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #424242;
}

.color-red {
  color: red !important;
}

.pdd-left-13 {
  padding-left: 13px;
}

//search bar css

#searchBar {
  color: #888da8;
  min-width: 315px;
  border-radius: 2px;
  background-color: #ffffff;
}

//borderless buttons

.border-none {
  border: none;
}

//Hightlight selected dropdown item

.dropdown-selected-item {
  color: #515365;
  text-decoration: none;
  background-color: #f6f7fb;
}

span {
  box-decoration-break: clone !important;
}

.min-width-20 {
  min-width: 20em;
}

.selected-status-pill {
  background: #f6f7fb;
}

.mrg-btm-0 {
  margin-bottom: 0px !important;
}

// Pill css by rawad
.center {
  max-width: 1600px;
  margin: auto;
}

.sideNav li:hover {
  color: white;
  background: #f6f7fb;
}

.sideNav li:active {
  color: white;
  background: #e9f2fa;
}

.sidenav {
  height: 100%;
  /* Full-height: remove this if you want "auto" height */
  width: 200px;
  /* Set the width of the sidebar */
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll) */

  padding-left: 0%;
  background-color: white;
  /* Black */
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 10px;
  z-index: 10;
}

/* When you mouse over the navigation links, change their color */
.sidenav li:hover {
  background: #f6f7fb;
}

.sidenav li:active {
  background: #e9f2fa;
}

.topnav {
  height: 50px;
  background-color: white;
  overflow: visible;
  /* margin-bottom: 0%; */
}

/* Change the color of links on hover */
.topnav a:hover {
  background: #f6f7fb;
}

/* Add a color to the active/current link */
.topnav a.active {
  background: #e9f2fa;
}

.center {
  position: relative;
  left: 40%;
  margin: 0 auto;
}

// End Pill css by rawad
@media (min-width: 1000px) {
  .right-main {
    margin-left: 215px;
    margin-right: 15px;
  }

  .right-main-project {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .hide-company-name {
    display: none;
  }
}

//all input radius 3px

input {
  border-radius: 3px !important;
}

//table cell heading

.cell-heading {
  color: #888da8;
}

.cell-description {
  color: #9cb1c3;
}

//white-space-nowrap

.white-space-nowrap {
  white-space: nowrap;
}

//background-transparent

.background-transparent {
  background: transparent;
}

//display-none

.display-none {
  display: none;
}

.txt-align-left {
  text-align: left;
}

.width-30 {
  width: 30px;
}

.height-381 {
  height: 381px;
}

.map-max-height {
  max-height: 200px;
}

.border-padd {
  border: none;
  padding: auto;
}

.border-padd-1 {
  border: none;
  padding: 1px;
}

.font-weight-lighter {
  font-weight: lighter;
}

.dropdown-btn-inverse {
  background-color: #fffae6 !important;
  color: #fc0;
  border-color: transparent !important;
  box-shadow: none !important;
}

.label-danger {
  background-color: white !important;
  color: #dc4a38;
}

//sweetalert fade
.swal2-container {
  z-index: 3005 !important;
}

//login-resetpassword-hyperlink color

.hyperlink-color {
  color: #0f9aee;
}

//powered-by-logo
.poweredby-logo {
  height: 20px;
  width: 140px;
  margin-right: -15px;
}

.countrycode-icon {
  font-style: inherit;
  position: absolute !important;
  left: 10px !important;
  top: 11px !important;
  color: #e0e2e9 !important;
}

//text-align: center

.text-align-center {
  text-align: center !important;
}

.status-custom {
  width: 10px;
  height: 10px;
  background-color: $default-white;
  border-radius: 50px;
  border: 3px solid;
  border-color: $border-color;
  position: absolute;
  margin-top: 6px;
  //left: 0px;
}

//red button

.btn-red-popup {
  background-color: #dc4a38;
  border-color: #dc4a38;
  color: #ffffff;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: lighten(#dc4a38, 5%);
    border-color: lighten(#dc4a38, 5%);
  }

  &:active {
    background-color: darken(#dc4a38, 5%);
    border-color: darken(#dc4a38, 5%);
  }

  &.active {
    background-color: darken(#dc4a38, 5%);
    border-color: darken(#dc4a38, 5%);

    &:hover,
    &:focus {
      background-color: darken(#dc4a38, 5%);
      border-color: darken(#dc4a38, 5%);
    }
  }

  &.btn-inverse {
    background-color: lighten(#dc4a38, 45%);
    color: #dc4a38;
    border-color: transparent;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: darken(lighten(#dc4a38, 45%), 5%);
      color: darken(#dc4a38, 5%);
      border-color: transparent;
    }
  }

  &.disabled {
    background-color: #dc4a38;
    border-color: #dc4a38;
    opacity: 0.35;

    &:hover,
    &:focus {
      background-color: #dc4a38;
      border-color: #dc4a38;
    }
  }
}

// Dashboard Perfect Scroll-bar Height

.dashboard-scrollbar-height {
  height: 380px;
}

.scrollbar-height {
  height: 27px;
}

//ngx-loader backdrop

.backdrop {
  z-index: 1000 !important;
  background-color: transparent !important;
}

//ngx-loader spinner

.spinner-circle-swish {
  z-index: 1000 !important;
}

//Virtual Scroll Style

#virtual-scroll-style:hover {
  overflow-y: auto;
}

#virtual-scroll-style::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #f5f5f5;
}

#virtual-scroll-style::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

#virtual-scroll-style::-webkit-scrollbar-track {
  background-color: #eee;
  opacity: 0.9;
}

//clear both

.clear-both {
  clear: both !important;
}

.pull-orderscount-right {
  float: right !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #ccc;
}

.form-group {
  margin-bottom: 1rem !important;
}

.label,
.tag {
  padding: 4px 7px !important;
}

.swl-text-size {
  font-size: 10px !important;
}

.grid-container {
  display: grid;
  grid-template-rows: 20% auto 8%;
  height: 100vh;
  background: white;
  //grid-row-gap: 20px
}

.item-header {
  grid-row: 1 / 2;
  place-self: center;
}

.item-body {
  grid-row: 2 / 3;
}

.item-body-center {
  grid-row: 2 / 3;
  place-self: center;
}

.item-footer {
  grid-row: 3 / 4;
  border-top: 1px solid $border-color;
  width: 100%;
}

.max-width-148 {
  max-width: 148px !important;
}

.min-width-80 {
  min-width: 80px !important;
}

.min-width-97 {
  min-width: 97px !important;
}

.min-width-140 {
  min-width: 140px !important;
}

.min-width-160 {
  min-width: 160px !important;
}

.min-width-250 {
  min-width: 250px !important;
}

.min-width-260 {
  min-width: 260px !important;
}

.min-width-400 {
  min-width: 400px !important;
}

@include screen-mobile {
  .flex-layout-mobile {
    display: flex;
    flex-direction: row;
  }

  .flex-layout-mobile::after {
    content: "";
    flex-grow: 1;
    order: 0;
  }

  .flex-layout-mobile-right {
    order: 1;
  }

  .page-title-project {
    font-size: 14px !important;
  }

  #searchBar {
    font-size: 12px;
    color: #888da8;
    height: 40px;
    min-width: auto !important;
    border-radius: 2px;
    background-color: #ffffff;
  }

  .cust-reset-pin-detail {
    background: white;
    z-index: 100;
    position: absolute;
    top: calc(21% - 50px) !important;
    left: calc(19% - 0px) !important;
  }
}

.line-height {
  line-height: 58px;
}

.margn-top {
  margin-top: 85px !important;
}

@media (max-width: 1050px) {
  .col-sm-cust-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ref-mrg {
    margin-top: 10px;
  }
}

.float-right {
  float: right !important;
}

.display-flex {
  display: flex;
}

.max-width-105 {
  max-width: 105px;
}

.ready-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #f6f7fb;
  background: #f6f7fb;
  padding: 10px;
}

.nav::after {
  content: "";
  flex-grow: 1;
  order: 0;
}

.nav-right-cust {
  order: 1;
}

.padd-left-0 {
  padding: 0px !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.padd-right-0 {
  padding-right: 0px !important;
}

.padd-right-5 {
  padding-right: 5px !important;
}

.padd-right-10 {
  padding-right: 10px !important;
}

.padd-right-20 {
  padding-right: 20px !important;
}

.padd-left-20 {
  padding-left: 20px !important;
}

.padd-left-30 {
  padding-left: 30px !important;
}

.text-align-end {
  text-align: end !important;
}

.incident-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.incident-container-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
  transition: opacity 0.2s;
  overflow-x: auto;
}

.incident-container-item1 {
  display: flex;
  flex-direction: column;
  width: 75.5%;
  table-layout: fixed;
  padding-bottom: 10px;
  transition: opacity 0.2s;
  overflow-x: auto;
  flex-basis: 80%;
}

.incident-container-item2 {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
}

//.with-collapse {
//  width: 21.5%;
//}
//.without-collapse {
//  width: 18.5%;
//}

.with-collapse-footer {
  width: 21.5%;
}

.without-collapse-footer {
  width: 18.5%;
}

@media only screen and (min-width: 1440px) {
  .with-collapse {
    width: 18.5%;
  }

  .with-collapse-footer {
    width: 18.5%;
  }
}

.font-style-inherit {
  font-style: inherit;
  color: #e0e2e9;
}

.input-custom-icon {
  position: absolute !important;
  left: 10px !important;
  top: 10px !important;
  color: #e0e2e9 !important;
  font-style: inherit;
}

.default-icon {
  font-size: 30px !important;
  color: lightgray;
}

body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #f6f7fb;
  padding: 0.571em 1em;
  text-align: center;
}

.ui-paginator .ui-paginator-left-content {
  margin-left: 10px !important;
  margin-top: 7px !important;
}

.sub-title-clr {
  color: #9799a7 !important;
}

.badge {
  display: inline-block;
  margin-right: 3px;
  min-width: 10px;
  font-weight: bold;
  color: #fff !important;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  background-color: #007BFF;
  padding: 3px 5px;
  border-radius: 10px;
}

.mrg-bottom-3 {
  margin-bottom: -3px !important;
}

.min-height-100vh {
  min-height: 100vh !important;
}

.min-height-50 {
  min-height: 50px;
}

.min-height-100 {
  min-height: 100px;
}

.min-height-150 {
  min-height: 150px;
}

.min-height-400 {
  min-height: 400px;
}

.md-drppicker .ranges ul li button.active {
  background-color: #0f9aee !important;
  color: #fff;
}

//Marketplace Order screen

.container-test {
  height: auto;
  overflow: hidden;
}

.hide-filter {
  display: none;
}

.right {
  width: 300px;
  float: right;
}

.left {
  float: none;
  /* not needed, just for clarification */
  /* the next props are meant to keep this block independent from the other floated one */
  width: auto;
  overflow: hidden;
  padding-right: 10px;
}

.filter-body {
  background-color: white;
  padding: 10px 15px 30px 15px;
  //margin-left: 15px;
  top: 117px;
  bottom: 60px;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 300px;
}

.filter-footer {
  background-color: white;
  position: fixed;
  height: 60px;
  padding: 0px 15px 0px 15px;
  bottom: 0;
  z-index: 15;
  width: 300px;
}

.min-table-height {
  min-height: 500px !important;
}

.md-drppicker .btn {
  background-color: #0f9aee !important;
}

.md-drppicker .clear svg {
  color: #0f9aee !important;
}

.modal-right .modal-dialog .modal-content,
.modal-left .modal-dialog .modal-content {
  height: unset !important;
}

.btn-container {
  display: flex !important;
  flex-direction: row;
}

.margin-top {
  margin-top: 5px;
}

@media only screen and (max-width: 575px) {
  .margin-top {
    margin-top: 10px;
  }
}

// ng-multi-select-dropdown Start
.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #e6ecf5 !important;
  width: 100%;
  padding: 9px 12px !important;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
}

.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
  color: lightgrey !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  display: inline-block;
  top: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid #9cb1c3 !important;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  display: inline-block;
  width: 0;
  height: 0;
  border-bottom: 10px solid #9cb1c3 !important;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  background-color: #e5f4fd !important;
  color: #0f9aee !important;
  background-image: none !important;
  border: 0px !important;
  border-radius: 50px !important;
  margin-right: 8px !important;
  margin-bottom: 6px !important;
  padding: 0px 10px !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-weight: 500 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
  text-decoration: none;
  color: #337ab7 !important;
}

// ng-multi-select-dropdown End

//primeNG multiselect Start
body .ui-multiselect {
  background: #ffffff;
  color: #888da8;
  border: 1px solid #e6ecf5;
  transition: border-color 0.2s;
  width: 100% !important;
}

body .ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #e6ecf5;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: #495057;
  background: #e3f2fd;
}

body .ui-multiselect .ui-multiselect-label {
  //padding: 0px !important;
  padding-top: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 10px !important;
  color: #888da8;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #888da8 !important;
  background-color: transparent;
  border-radius: 0;
}

body .ui-multiselect .ui-multiselect-trigger {
  background-color: #ffffff;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #888da8 !important;
}

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close {
  color: #888da8 !important;
  top: 50%;
  margin-top: -0.5em;
  transition: color 0.2s;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
  margin-top: 7px !important;
}

//primeNG multiselect End
.default-sub-header {
  background: #f6f7fb !important;
  height: 52px !important;
}

.zero-mrgn-right {
  margin-right: 0 !important;
}

.error-class {
  border: 1px solid #dc4a38 !important;
}

.error-msg {
  color: #dc4a38 !important;
}

.modal-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 1000;
}

.form-control {
  &.is-valid {
    border-color: #1565d8;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(21 101 216 / 25%);
    }
  }

  &.is-invalid {
    &:focus {
      border-color: #dc3545;
    }

  }

  color: black;
  padding: 10px 5px 10px 5px;
}

.ui-button {
  padding: 2px;
  background-color: #0f9aee;
  border-color: #3daef3;
  font-size: 13px;

  .ui-button-icon-left {
    font-size: 22px;
  }
}

.time-input {
  border: 1px solid #e6ecf5 !important;

  &:hover {
    border-color: none !important;
  }

  &:disabled {
    background-color: #d6d8da !important;
  }
}

.time-custom-input {
  border: 1px solid #e6ecf5 !important;
  height: 42px;
  width: 247px;
  color: #888da8 !important;

  &:hover {
    border-color: none !important;
  }

  &:disabled {
    background-color: #d6d8da !important;
  }
}

.disable-multiselect {
  background-color: #e9ecef !important;

  .ui-multiselect-label {
    color: #000 !important
  }
}

.tab-header {
  display: flex;
  width: 100%;
  position: relative;
  padding: 0px;
  border-bottom: 1px solid #e6ecf5;
  margin-bottom: 0px;
  transition: all 0.2s ease;
  box-shadow: -4px 0 24px 0 rgba(0, 0, 0, 0.05);

  .tab-header-container {
    width: 100%;

    ul {
      list-style-type: none;
      display: inline-block;
      padding-left: 15px;

      li {
        display: inline;
      }
    }

    .nav-right {
      float: right;
    }
  }
}

.group-table .ui-table-scrollable-body {
  height: 400px !important;
}

.group-filter .dropdown {
  z-index: 1001;
}

.field-error {
  box-shadow: none;
  border-color: $default-danger !important;

}

.border-btm-dashed {
  border-bottom: 1px dashed
}

.custom-form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.custom-form-container-item {
  flex-basis: auto;
  /* default auto */
  margin-right: 20px;
}

.custom-form-container::after {
  content: '';
  flex-grow: 1;
  order: 0;
}

.custom-form-container-item-right {
  order: 1;
}

.input-group-append-custom {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  //padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #ffffff !important;
}

.btn-group .btn {
  padding: 7px 12px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.center-div {
  width: 800px;
  margin: 0 auto;
}

.has-error {
  border-color: #dc4a38 !important;
}

.receipt-form {
  p-calendar {
    span {
      width: 100%;

      input {
        width: 100%;
        height: 42px;
      }
    }
  }
}

.pac-container {
  z-index: 3000;
}

.deal-product-spinner {
  .wrapper {
    height: 22px;

    button {
      padding: 2px !important;
    }
  }
}
